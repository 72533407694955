<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm5.416-17.704a1.774 1.774 0 0 0-2.509 0l-8.352 8.352a1.774 1.774 0 1 0 2.509 2.509l8.352-8.352a1.774 1.774 0 0 0 0-2.51Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
