<template>
  <table class="min-w-full text-xs text-left">
    <slot />
  </table>
</template>

<style class="postcss" scoped>
table {
  margin: 0 0rem;
  width: calc(100%);

  &:deep(thead) tr th {
    @apply font-medium leading-[18px] pb-4 px-2;
  }

  &:deep(tbody) tr {
    td {
      @apply  align-top leading-[26px] px-2 py-2;
    }

    &:not(:last-child) {
      @apply border-b;
      border-color: #eee;
    }
  }
}
</style>
