<script setup lang="ts">
import { ref } from 'vue'
import type { User } from '../types/account'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import axiosClient from '../axiosClient'
import { useRoute } from 'vue-router'

const route = useRoute()
const isLoading = ref<boolean>(true)

const user = ref<User>()

axiosClient.get('user/' + route.params.id).then((response) => {
  const { data } = response
  user.value = data
  document.title = user.value?.email + ' - Airon'
  isLoading.value = false
})
</script>

<template>
  <div>
    <h1
      class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
    >
      {{ user?.name }}
    </h1>
    <h2 v-if="user?.email" class="text-xl font-euclid text-neutral-500 mb-5">
      {{ user.email }}
    </h2>
    <div v-if="!isLoading && user">
      <dl>
        <!--dt class="text-blue-950 font-semibold">ID</dt>
        <dd>{{ user.user_id }}</dd>
        <dt class="text-blue-950 font-semibold">Name</dt>
        <dd>{{ user.name }}</dd>-->
        <dt class="text-blue-950 font-semibold">Role</dt>
        <dd>{{ user.role_name }}</dd>
      </dl>
    </div>
    <SpinnerLoader v-else-if="isLoading" />
    <div v-else class="p-10">No results</div>
  </div>
</template>
