<script lang="ts" setup>
import SEIcon from '@/assets/icons/flags/SE.svg?component'

defineProps({
  country: {
    type: String,
    required: true
  }
})

const flag = {
  SE: SEIcon
} as any
</script>

<template>
  <component :is="flag[country]" />
</template>
