<script setup lang="ts">
import { ref, computed } from 'vue'
import axiosClient from '../axiosClient'
import { loadStripe } from '@stripe/stripe-js'
import type { StripeElements, StripePaymentElement } from '@stripe/stripe-js'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import type { BillingInformation } from '../types/account.d.ts'

import NotificationCard from '../components/NotificationCard.vue'
import AlertDialogue from '../components/AlertDialogue.vue'
import { TrashIcon } from '@heroicons/vue/24/outline'

interface Card {
  brand: string
  country: string
  exp_month: number
  exp_year: number
  fingerprint: string
  last4: string
}

interface PaymentMethod {
  id: string
  card: Card
}

const balance = ref<number>(0.0)
const totalSpend = ref<number>(0.0)
const usage = ref<number>(0.0)
const timeRemaining = ref<string>('--')

const addMethodOpen = ref<boolean>(false)
const addMethodLoading = ref<boolean>(false)
const addMethodError = ref<string>('')
const publicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(publicKey)

const isLoadingBilling = ref<boolean>(false)
const billInfo = ref<BillingInformation | null>(null)
const showBillInfo = ref<BillingInformation>({} as BillingInformation)

const paymentMethods = ref<PaymentMethod[] | null>(null)
const deleteMethodOpen = ref<boolean>(false)
const methodToDeleteIdx = ref<number>(-1)
const selectedMethod = ref<PaymentMethod | null>(null)

const autoTopUpCheckbox = ref<boolean>(false)
const autoTopUp = ref<string>('')
const newAutoTopUp = ref<string>('')

const history = ref<Payment[]>([])

const amount = ref<string>('')

const isPaymentProcessing = ref<boolean>(false)
const isSetTopUpProcessing = ref<boolean>(false)

// TODO: Clear others when setting one.
const successMessage = ref<string>('')
const infoMessage = ref<string>('')
const errorMessage = ref<string>('')

axiosClient.get('/user/organization-status').then((response) => {
  if (response.data === 0) {
    infoMessage.value = 'Your organization is pending approval. '
  }
})

axiosClient.get('/user/auto-top-up').then((response) => {
  autoTopUp.value = (response.data / 100).toString()
  newAutoTopUp.value = autoTopUp.value
  if (parseFloat(autoTopUp.value) > 0) {
    autoTopUpCheckbox.value = true
  }
  
})
axiosClient.get('/monitor/usage').then((response) => {
  balance.value = response.data.balance
  totalSpend.value = response.data.total_spend
  usage.value = response.data.usage
  timeRemaining.value = response.data.time_remaining
})

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const stats = computed(() => [
  { name: 'Balance', value: formatter.format(balance.value) },
  { name: 'Total Spend', value: formatter.format(totalSpend.value) },
  { name: 'Usage', value: formatter.format(usage.value) + '/h' },
  { name: 'Estimated time remaining', value: timeRemaining.value }
])

const getHistory = () => {
  axiosClient.get("/user/payment-history").then(res => {
    history.value = res.data
  })
}
getHistory()

const deposit = () => {
  if (selectedMethod.value) {
    isPaymentProcessing.value = true
    axiosClient
      .post('/user/create-payment-intent', {
        amount: parseFloat(amount.value),
        payment_method_id: selectedMethod.value.id
      })
      .then((res) => {
        console.log(res.data)
        if (res.data && res.data.length > 0) {
          stripePromise.then((stripe) => {
            if (!stripe) return
            stripe
              .confirmPayment({
                clientSecret: res.data,
                confirmParams: {
                  return_url: location.origin + '/billing/'
                }
              })
              .then((res) => {
                if (res.error) {
                  errorMessage.value = res.error.message!
                }
                isPaymentProcessing.value = false
              })
          })
        }
      })
      .catch((err) => {
        errorMessage.value = err.response.data.message
        isPaymentProcessing.value = false
      })
  }
}

const getAutoTopUp = () => {
  axiosClient.get('/user/auto-top-up').then((res) => {
    autoTopUp.value = (res.data / 100).toString()
    newAutoTopUp.value = autoTopUp.value
  })
}

const setAutoTopUp = () => {
  if (!autoTopUpCheckbox.value) {
    newAutoTopUp.value = '0'
  }
  axiosClient
    .post('/user/auto-top-up', {
      amount: parseFloat(newAutoTopUp.value)
    })
    .then(() => {
      successMessage.value = 'Auto top-up settings updated.'
      getAutoTopUp()
    })
    .catch((err) => {
      errorMessage.value = err.response.data.message
    })
}

const getPaymentMethods = () => {
  axiosClient.get('/user/payment-methods').then((res) => {
    paymentMethods.value = res.data
    selectedMethod.value = res.data[0]
  })
}

const getBillingInformation = () => {
  isLoadingBilling.value = true
  axiosClient
    .get('/user/billing')
    .then((res) => {
      billInfo.value = res.data
      if (billInfo.value) {
        showBillInfo.value = billInfo.value
        getPaymentMethods()
      }
      isLoadingBilling.value = false
    })
    .catch(() => {
      billInfo.value = null
      showBillInfo.value = {} as BillingInformation
      isLoadingBilling.value = false
    })
}
getBillingInformation()

const saveBillingInformation = () => {
  isLoadingBilling.value = true
  axiosClient
    .post('/user/save-billing', showBillInfo.value)
    .then(() => {
      getBillingInformation()
    })
    .catch((err) => {
      errorMessage.value = err.response.data.message
      isLoadingBilling.value = false
    })
}

const stripePaymentElement = ref<HTMLElement>()
let stripeElements: StripeElements | undefined
let stripePaymentForm: StripePaymentElement
let setupIntentsId: string
const openAddMethod = () => {
  axiosClient.post('/user/create-setup-intents').then((res) => {
    stripePromise.then((stripe) => {
      setupIntentsId = res.data.id
      stripeElements = stripe?.elements({ clientSecret: res.data.client_secret })
      if (stripeElements) {
        stripePaymentForm = stripeElements.create('payment', {})
        if (stripePaymentElement) {
          stripePaymentForm.mount(stripePaymentElement.value!)
        }
      }
    })
  })
  .catch((err) => {
    errorMessage.value = err.response.data.message
    addMethodOpen.value = false
  })
    addMethodOpen.value = true
}

const cancelSetupIntents = () => {
  axiosClient.post('/user/cancel-setup-intents/' + setupIntentsId)
}

const addMethod = () => {
  addMethodLoading.value = true
  stripePromise.then((stripe) => {
    if (stripe) {
      if (billInfo.value) {
        stripe
          .confirmSetup({
            elements: stripeElements!,
            confirmParams: {
              return_url: location.origin + '/billing',
              payment_method_data: {
                billing_details: {
                  name: billInfo.value.first_name + ' ' + billInfo.value.last_name,
                  address: {
                    city: billInfo.value.city,
                    country: billInfo.value.country,
                    line1: billInfo.value.street_address,
                    postal_code: billInfo.value.zip,
                    state: billInfo.value.state
                  }
                }
              }
            }
          })
          .then((result) => {
            if (result.error) {
              addMethodError.value = result.error.message!
            }
            addMethodLoading.value = false
          })
      }
    }
  })
}

const deleteMethod = () => {
  deleteMethodOpen.value = false
  if (methodToDeleteIdx.value < 0 || paymentMethods.value == null) return
  const id = paymentMethods.value[methodToDeleteIdx.value].id
  paymentMethods.value = null
  axiosClient.post('/user/delete-payment-method/' + id).then((res) => {
    getPaymentMethods()
  })
}

const redirectStatus = new URLSearchParams(window.location.search).get('redirect_status')

const setupIntentClientSecret = new URLSearchParams(window.location.search).get(
  'setup_intent_client_secret'
)

if (setupIntentClientSecret && redirectStatus) {
  switch (redirectStatus) {
    case 'succeeded': {
      successMessage.value = 'Success! Your payment method has been added.'
      break
    }

    case 'processing': {
      infoMessage.value =
        "Processing payment details. We'll update you when processing is complete."
      break
    }

    case 'requires_payment_method': {
      infoMessage.value = 'Failed to process payment details. Please try another payment method.'
      break
    }
  }
}

const paymentIntentClientSecret = new URLSearchParams(window.location.search).get(
  'payment_intent_client_secret'
)
if (paymentIntentClientSecret && redirectStatus) {
  switch (redirectStatus) {
    case 'succeeded': {
      successMessage.value = 'Success! Your payment was successful.'
      break
    }

    case 'processing': {
      infoMessage.value = "Still processing payment. We'll update you when processing is complete."
      break
    }

    case 'requires_payment_method': {
      infoMessage.value = 'Failed to verify payment. Please try again later.'
      break
    }
  }
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function cardExpString(card: Card): string {
  const month = ('0' + card.exp_month).slice(-2)
  const year = ('' + card.exp_year).slice(-2)
  return month + '/' + year
}


interface Payment {
  id: number,
  org_id: number,
  user_id: string,
  method: string,
  time: string,
  amount: number,
  status: number 
}

</script>

<template>
  <div>
    <div class="border-b border-airon-border pb-4">
      <h1 class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        Billing
      </h1>
    </div>

    <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 border-b">
      <div v-for="stat in stats" :key="stat.name"
        class="flex flex-wrap items-baseline justify-between gap-x-4 bg-airon-lighter gap-y-2 px-4 py-10 sm:px-6 xl:px-8">
        <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
        <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {{ stat.value }}
        </dd>
      </div>
    </dl>

    <div>
      <NotificationCard class="mt-8" :data="{
        type: 'success',
        message: successMessage,
        link_text: '',
        link_to: ''
      }" />
      <NotificationCard class="mt-8" :data="{
        type: 'info',
        message: infoMessage,
        link_text: '',
        link_to: ''
      }" />
      <NotificationCard class="mt-8" :data="{
        type: 'error',
        message: errorMessage,
        link_text: '',
        link_to: ''
      }" />

      <div class="flex flex-col md:flex-row gap-8 mt-8" v-if="billInfo">
        <form
          class="relative flex flex-col justify-between flex-grow w-full sm:max-w-md bg-white p-3 rounded-md shadow-sm"
          @submit.prevent="setAutoTopUp">
          <div v-if="isSetTopUpProcessing" class="z-10 absolute inset-0 rounded-md bg-gray-100">
            <div class="flex w-full h-full items-center justify-center">
              <p class="animate-pulse text-lg">Processing request...</p>
            </div>
          </div>
          
          <div>
            <div class="mt-2">
            <label for="amount">Enable auto top-up</label>
              <input type="checkbox" name="auto-top-up-checkbox" id="auto-top-up-checkbox" v-model="autoTopUpCheckbox"
                class="rounded border-1 mx-2 p-2 ring-0 focus:ring-0"/>
                </div> 
                <div v-if="parseFloat(autoTopUp) > 0">
                <span class="inline-block outline outline-3 h-2 aspect-square rounded-full bg-green-500 outline-green-200"></span><p class="mx-2 text-gray-500 sm:text-sm inline-block">Enabled: {{ autoTopUp }} USD</p>
                </div>
                <div v-else>
                <span class="inline-block outline outline-3 h-2 aspect-square rounded-full bg-red-500 outline-red-200"></span><p class="mx-2 text-gray-500 sm:text-sm inline-block">Disabled</p>
                </div>
            <div v-if="autoTopUpCheckbox">
              <div class="relative mt-2 rounded-md shadow-sm">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">$</span>
                </div>
                <input id="amount" type="number" min="5" step="0.01" name="amount" v-model="newAutoTopUp"
                  class="block w-full bg-white rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  :placeholder="autoTopUp.toString()" aria-describedby="price-currency" required />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span id="price-currency" class="text-gray-500 sm:text-sm">USD</span>
                </div>
              </div>
           </div>
            <div v-else-if="paymentMethods == null" class="mt-2 bg-gray-100 text-gray-400 animate-pulse rounded-md p-4">
              <p class="py-4">Loading...</p>
            </div>
            <div v-else>
              <!-- <p class="text-sm text-gray-500 py-4">Add a payment method before enabling...</p> -->
            </div>
          </div>
          <button type="submit"
            class="transition-colors mt-2 w-full rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200">
            Submit
          </button>
        </form>
        <form
          class="relative flex flex-col justify-between flex-grow w-full sm:max-w-md bg-white p-3 rounded-md shadow-sm"
          @submit.prevent="deposit">
          <div v-if="isPaymentProcessing" class="z-10 absolute inset-0 rounded-md bg-gray-100">
            <div class="flex w-full h-full items-center justify-center">
              <p class="animate-pulse text-lg">Processing payment...</p>
            </div>
          </div>
          
          <div>
            <label for="amount" class="block">Deposit</label>
            <div v-if="selectedMethod">
              <div class="relative mt-2 rounded-md shadow-sm">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span class="text-gray-500 sm:text-sm">$</span>
                </div>
                <input id="amount" type="number" min="5" step="0.01" name="amount" v-model="amount"
                  class="block w-full bg-white rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                  placeholder="5.00" aria-describedby="price-currency" required />
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span id="price-currency" class="text-gray-500 sm:text-sm">USD</span>
                </div>
              </div>
              <Listbox as="div" v-model="selectedMethod" class="mt-2">
                <ListboxLabel class="sr-only block text-sm font-medium leading-6 text-gray-900">
                  Payment Method
                </ListboxLabel>
                <div class="relative mt-2">
                  <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-600 sm:text-sm sm:leading-6">
                    <span class="block truncate">
                      <img :src="'/card-brand-icons/' + selectedMethod.card.brand + '.svg'" class="inline h-5" />
                      <span class="ml-2 font-semibold">
                        **** **** **** {{ selectedMethod.card.last4 }}
                      </span>
                      <span class="ml-4">
                        {{ cardExpString(selectedMethod.card) }}
                      </span>
                    </span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption as="template" v-for="method in paymentMethods" :key="method.id" :value="method"
                        v-slot="{ active, selected }">
                        <li :class="[
                          active ? 'bg-gray-800 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9 transition-colors'
                        ]">
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                            <!--img
                          :src="'/card-brand-icons/' + method.card.brand + '.svg'"
                          class="inline h-5"
                        /-->
                            <div class="flex justify-between">
                              <div>
                                <span>{{ capitalizeFirstLetter(method.card.brand) }}</span>
                                <span class="ml-2">**** {{ method.card.last4 }}</span>
                              </div>
                              <span class="mr-2">Expires {{ cardExpString(method.card) }}</span>
                            </div>
                          </span>

                          <span v-if="selected" :class="[
                            active ? 'text-white' : 'text-gray-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          ]">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <div v-else-if="paymentMethods == null" class="mt-2 bg-gray-100 text-gray-400 animate-pulse rounded-md p-4">
              <p class="py-4">Loading...</p>
            </div>
            <div v-else>
              <p class="text-sm text-gray-500 py-4">Add a payment method before depositing...</p>
            </div>
          </div>
          <button type="submit"
            class="transition-colors mt-2 w-full rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200">
            Deposit
          </button>
        </form>

        <div class="flex flex-col justify-between flex-grow w-full sm:max-w-md bg-white p-3 rounded-md shadow-sm">
          <div>
            <h3 class="">Payment Methods</h3>
            <div v-if="paymentMethods == null" class="mt-2 bg-gray-100 text-gray-400 animate-pulse rounded-md p-4">
              <p class="py-4">Loading...</p>
            </div>
            <div v-else-if="paymentMethods.length == 0" class="text-sm text-gray-500 py-4">
              <p>Add a new payment method for depositing cash to your balance.</p>
            </div>
            <ul class="divide-y max-h-48 overflow-y-auto">
              <li v-for="(method, i) in paymentMethods" :key="method.id" class="py-2 flex justify-between items-center">
                <div class="flex items-center">
                  <img :src="'/card-brand-icons/' + method.card.brand + '.svg'" class="h-5" />
                  <span class="ml-2 font-semibold">**** **** **** {{ method.card.last4 }}</span>
                  <span class="ml-4">
                    {{ cardExpString(method.card) }}
                  </span>
                </div>
                <div class="flex items-center">
                  <button type="button" @click="(methodToDeleteIdx = i), (deleteMethodOpen = true)"
                    class="block bg-gray-100 p-1 text-gray-300 hover:bg-red-500 hover:text-red-100 transition-colors rounded-md">
                    <TrashIcon class="w-5 h-5" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <button @click="openAddMethod" type="button"
            class="transition-colors mt-2 w-full rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
            Add Method
          </button>
        </div>
      </div>

      <div class="mt-8 bg-white p-8 rounded-md shadow-sm max-w-screen-2xl" v-if="history && history.length > 0">
        <h3 class="font-medium text-xl">Billing History</h3>

        <table class="min-w-full divide-y divide-gray-300 ">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Status</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Method</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="bill in history" :key="bill.id">
              <!--td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ bill.user_id || '---' }} </td-->
              <td class="flex items-center gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                <span :class="{'inline-block  outline  outline-3 h-2 aspect-square rounded-full' : true, 'bg-green-500 outline-green-200': bill.status == 1, 'bg-red-500 outline-red-200': bill.status == 2 }"></span>
                {{ bill.status == 0 ? 'Unknown' : bill.status == 1 ? 'Successful' : 'Failed' }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ bill.method }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ new Date(bill.time).toLocaleString() }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">{{ formatter.format(bill.amount / 100) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <form @submit.prevent="saveBillingInformation"
        class="relative mt-16 grid grid-cols-1 gap-x-8 gap-y-10 pb-12 md:grid-cols-3">
        <transition enter-active-class="duration-200 ease-out" enter-from-class="transform opacity-0"
          enter-to-class="opacity-100" leave-active-class="duration-200 ease-in" leave-from-class="opacity-100"
          leave-to-class="transform opacity-0">
          <div v-if="isLoadingBilling"
            class="absolute max-w-2xl sm:col-start-2 w-full rounded-xl h-full bg-gray-100 text-gray-500">
            <div class="flex items-center justify-center h-full">
              <p class="animate-pulse">Loading billing information...</p>
            </div>
          </div>
        </transition>

        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Billing Information</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Fill in some essential information used for billing.
          </p>
        </div>

        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
          <div class="sm:col-span-3">
            <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">
              Company name
            </label>
            <div class="mt-2">
              <input type="text" name="company-name" id="company-name" autocomplete="organization"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.company_name" required />
            </div>
          </div>

          <!-- TODO: disabled if done -->
          <div class="sm:col-start-1 sm:col-span-3">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">
              Tax ID Type
            </label>
            <div class="mt-2">
              <select id="tax-id-type" name="tax-id-type" autocomplete=""
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:max-w-xs sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                v-model="showBillInfo.tax_id_type" :disabled="billInfo != null" required>
                <option value=""></option>
                <option value="ad_nrt">AD NRT (Andorra)</option>
                <option value="ae_trn">AE TRN (United Arab Emirates)</option>
                <option value="ar_cuit">AR CUIT (Argentina)</option>
                <!--<option value="eu_vat">AT VAT (Austria)</option>-->
                <option value="au_abn">AU ABN (Australia)</option>
                <option value="au_arn">AU ARN (Australia)</option>
                <!--<option value="eu_vat">BE VAT (Belgium)</option>-->
                <option value="bg_uic">BG UIC (Bulgaria)</option>
                <option value="bg_vat">BG VAT (Bulgaria)</option>
                <option value="bo_tin">BO TIN (Bolivia)</option>
                <option value="br_cnpj">BR CNPJ (Brazil)</option>
                <option value="br_cpf">BR CPF (Brazil)</option>
                <option value="ca_bn">CA BN (Canada)</option>
                <option value="ca_gst_hst">CA GST/HST (Canada)</option>
                <option value="ca_pst_bc">CA PST-BC (Canada)</option>
                <option value="ca_pst_mb">CA PST-MB (Canada)</option>
                <option value="ca_pst_sk">CA PST-SK (Canada)</option>
                <option value="ca_qst">CA QST (Canada)</option>
                <option value="ch_vat">CH VAT (Switzerland)</option>
                <option value="cl_tin">CL TIN (Chile)</option>
                <option value="cn_tin">CN TIN (China)</option>
                <option value="co_nit">CO NIT (Colombia)</option>
                <option value="cr_tin">CR TIN (Costa Rica)</option>
                <!--<option value="eu_vat">CY VAT (Cyprus)</option>-->
                <!--<option value="eu_vat">CZ VAT (Czech Republic)</option>-->
                <!--<option value="eu_vat">DE VAT (Germany)</option>-->
                <!--<option value="eu_vat">DK VAT (Denmark)</option>-->
                <option value="do_rcn">DO RCN (Dominican Republic)</option>
                <option value="ec_ruc">EC RUC (Ecuador)</option>
                <!--<option value="eu_vat">EE VAT (Estonia)</option>-->
                <option value="eg_tin">EG TIN (Egypt)</option>
                <option value="es_cif">ES CIF (Spain)</option>
                <!--<option value="eu_vat">ES VAT (Spain)</option>-->
                <option value="eu_vat">EU VAT (European Union)</option>
                <!--<option value="eu_vat">FI VAT (Finland)</option>-->
                <!--<option value="eu_vat">FR VAT (France)</option>-->
                <option value="gb_vat">GB VAT (United Kingdom)</option>
                <option value="ge_vat">GE VAT (Georgia)</option>
                <!--<option value="eu_vat">GR VAT (Greece)</option>-->
                <option value="hk_br">HK BR (Hong Kong SAR China)</option>
                <!--<option value="eu_vat">HR VAT (Croatia)</option>-->
                <option value="hu_tin">HU TIN (Hungary)</option>
                <option value="hu_vat">HU VAT (Hungary)</option>
                <option value="id_npwp">ID NPWP (Indonesia)</option>
                <!--<option value="eu_vat">IE VAT (Ireland)</option>-->
                <option value="il_vat">IL VAT (Israel)</option>
                <option value="in_gst">IN GST (India)</option>
                <option value="is_vat">IS VAT (Iceland)</option>
                <option value="jp_cn">JP CN (Japan)</option>
                <option value="jp_rn">JP RN (Japan)</option>
                <option value="jp_trn">JP TRN (Japan)</option>
                <option value="ke_pin">KE PIN (Kenya)</option>
                <option value="kr_brn">KR BRN (South Korea)</option>
                <option value="li_uid">LI UID (Liechtenstein)</option>
                <!--<option value="eu_vat">LT VAT (Lithuania)</option>-->
                <!--<option value="eu_vat">LU VAT (Luxembourg)</option>-->
                <!--<option value="eu_vat">LV VAT (Latvia)</option>-->
                <!--<option value="eu_vat">MT VAT (Malta)</option>-->
                <option value="mx_rfc">MX RFC (Mexico)</option>
                <option value="my_frp">MY FRP (Malaysia)</option>
                <option value="my_itn">MY ITN (Malaysia)</option>
                <option value="my_sst">MY SST (Malaysia)</option>
                <!--<option value="eu_vat">NL VAT (Netherlands)</option>-->
                <option value="no_vat">NO VAT (Norway)</option>
                <option value="no_voec">NO VOEC (Norway)</option>
                <option value="nz_gst">NZ GST (New Zealand)</option>
                <option value="pe_ruc">PE RUC (Peru)</option>
                <option value="ph_tin">PH TIN (Philippines)</option>
                <!--<option value="eu_vat">PL VAT (Poland)</option>-->
                <!--<option value="eu_vat">PT VAT (Portugal)</option>-->
                <option value="ro_tin">RO TIN (Romania)</option>
                <!--<option value="eu_vat">RO VAT (Romania)</option>-->
                <option value="rs_pib">RS PIB (Serbia)</option>
                <option value="ru_inn">RU INN (Russia)</option>
                <option value="ru_kpp">RU KPP (Russia)</option>
                <option value="sa_vat">SA VAT (Saudi Arabia)</option>
                <!--<option value="eu_vat">SE VAT (Sweden)</option>-->
                <option value="sg_gst">SG GST (Singapore)</option>
                <option value="sg_uen">SG UEN (Singapore)</option>
                <option value="si_tin">SI TIN (Slovenia)</option>
                <!--<option value="eu_vat">SI VAT (Slovenia)</option>-->
                <!--<option value="eu_vat">SK VAT (Slovakia)</option>-->
                <option value="sv_nit">SV NIT (El Salvador)</option>
                <option value="th_vat">TH VAT (Thailand)</option>
                <option value="tr_tin">TR TIN (Turkey)</option>
                <option value="tw_vat">TW VAT (Taiwan)</option>
                <option value="ua_vat">UA VAT (Ukraine)</option>
                <option value="us_ein">US EIN (United States)</option>
                <option value="uy_ruc">UY RUC (Uruguay)</option>
                <option value="ve_rif">VE RIF (Venezuela)</option>
                <option value="vn_tin">VN TIN (Vietnam)</option>
                <!--<option value="eu_vat">XI VAT (United Kingdom)</option>-->
                <option value="za_vat">ZA VAT (South Africa)</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="business-tax-id" class="block text-sm font-medium leading-6 text-gray-900">
              Business tax ID
            </label>
            <div class="mt-2">
              <input type="text" name="business-tax-id" id="business-tax-id" autocomplete="off"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                v-model="showBillInfo.tax_id" :disabled="billInfo != null" required />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">
              First name
            </label>
            <div class="mt-2">
              <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.first_name" required />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">
              Last name
            </label>
            <div class="mt-2">
              <input type="text" name="last-name" id="last-name" autocomplete="family-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.last_name" required />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">
              Country
            </label>
            <div class="mt-2">
              <select id="country" name="country" autocomplete="country-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:max-w-xs sm:text-sm sm:leading-6"
                v-model="showBillInfo.country" required>
                <option value=""></option>
                <option value="United States">United States</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antartica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">
                  British Indian Ocean Territory
                </option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo">Congo, the Democratic Republic of the</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                <option value="Croatia">Croatia (Hrvatska)</option>
                <option value="Cuba">Cuba</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="France Metropolitan">France, Metropolitan</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                <option value="Holy See">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran">Iran (Islamic Republic of)</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Democratic People's Republic of Korea">
                  Korea, Democratic People's Republic of
                </option>
                <option value="Korea">Korea, Republic of</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia">Micronesia, Federated States of</option>
                <option value="Moldova">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint LUCIA</option>
                <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia (Slovak Republic)</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                <option value="Span">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="St. Helena">St. Helena</option>
                <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syrian Arab Republic</option>
                <option value="Taiwan">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States Minor Outlying Islands">
                  United States Minor Outlying Islands
                </option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Viet Nam</option>
                <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Serbia">Serbia</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </div>
          </div>

          <div class="col-span-full">
            <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">
              Street address
            </label>
            <div class="mt-2">
              <input type="text" name="street-address" id="street-address" autocomplete="street-address"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.street_address" required />
            </div>
          </div>

          <div class="sm:col-span-2 sm:col-start-1">
            <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City</label>
            <div class="mt-2">
              <input type="text" name="city" id="city" autocomplete="address-level2"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.city" required />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="region" class="block text-sm font-medium leading-6 text-gray-900">
              State / Province
            </label>
            <div class="mt-2">
              <input type="text" name="region" id="region" autocomplete="address-level1"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.state" required />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">
              ZIP / Postal code
            </label>
            <div class="mt-2">
              <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                v-model="showBillInfo.zip" required />
            </div>
          </div>

          <div class="col-start-0 col-span-full">
            <button type="submit"
              class="block mt-2 w-full rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <AlertDialogue type="info" title="Add payment method" submitText="Add" :open="addMethodOpen"
    @close="cancelSetupIntents(), (addMethodOpen = false)" @submit="addMethod">
    <div class="relative min-h-64">
      <p class="text-red-500 text-md mb-2 bg-red-50 p-2 rounded-md" v-if="addMethodError.length > 0">
        {{ addMethodError }}
      </p>
      <div ref="stripePaymentElement" class=""></div>
      <div class="mt-2 absolute -inset-2 backdrop-blur-sm" v-if="addMethodLoading">
        <div class="flex items-center justify-center w-full h-full">
          <p class="text-gray-400 font-light text-lg animate-pulse">Loading...</p>
        </div>
      </div>
    </div>
  </AlertDialogue>

  <AlertDialogue type="danger" title="Delete payment method" submitText="Delete" description="Are you sure you want to delete this payment method? You will no longer be able to make payments using this method.
    It can always be added again later on." :open="deleteMethodOpen" @close="deleteMethodOpen = false"
    @submit="deleteMethod" />
</template>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  /* Firefox */
}
</style>