<script setup lang="ts">
import axiosClient from '../axiosClient'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { onMounted, ref } from 'vue'
import { PlusIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'
import AlertDialogue from '../components/AlertDialogue.vue'
import AddKeyPopoutPanel from '../components/AddKeyPopoutPanel.vue'
import { useGlobalStore } from '../stores/global'
import type { SSHKey } from '../types/machine.d.ts'

const isLoading = ref<boolean>(true)
const errorMessage = ref<string>('')
const keys = ref<Array<SSHKey>>([])

const keyToDeleteIdx = ref<number>(-1)
const deleteKeyDialogue = ref<boolean>(false)

const keyCopiedIdx = ref<number>(-1)

const { fetchNotifications } = useGlobalStore()

const fetchData = () => {
  isLoading.value = true
  axiosClient
    .get('key/list')
    .then((response) => {
      const { data } = response
      isLoading.value = false

      if (data == null) {
        keys.value = []
        return
      }

      keys.value = data

      for (let i = 0; i < keys.value.length; ++i) {
        const key = keys.value[i]

        const date = new Date(key.created_at)
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2)
        const day = ('0' + date.getUTCDate()).slice(-2)
        key.created_at = `${date.getUTCFullYear()}-${month}-${day} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`
      }
    })
    .catch((error) => {
      if (error.response) {
        errorMessage.value = error.response.data.message
      } else {
        errorMessage.value = error.message
      }
      isLoading.value = false
    })
}

onMounted(() => {
  fetchData()
})

const addKeyOpen = ref<boolean>(false)

const keyAdded = () => {
  fetchData()
  fetchNotifications()
}

const openDeleteDialogue = (i: number) => {
  deleteKeyDialogue.value = true
  keyToDeleteIdx.value = i
}

const deleteKey = () => {
  const id = keys.value[keyToDeleteIdx.value].id
  keyToDeleteIdx.value = -1
  deleteKeyDialogue.value = false

  axiosClient
    .get(`/key/delete/${id}`)
    .then(() => {
      fetchNotifications()
      fetchData()
    })
    .catch((error) => {
      errorMessage.value = error.response.data.message
      isLoading.value = false
    })
}

const copyKey = (idx: number) => {
  navigator.clipboard.writeText(keys.value[idx].key)
  keyCopiedIdx.value = idx
  setTimeout(() => {
    if (keyCopiedIdx.value === idx) {
      keyCopiedIdx.value = -1
    }
  }, 1000)
}
</script>

<template>
  <div>
    <div class="md:flex md:items-center md:justify-between border-b border-airon-border pb-4 mb-4">
      <div class="min-w-0 flex-1">
        <h1
          class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
        >
          Keys
        </h1>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0">
        <button
          type="button"
          class="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          @click="addKeyOpen = true"
        >
          <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add Key
        </button>
      </div>
    </div>

    <ErrorMessage :message="errorMessage" />

    <SpinnerLoader v-if="isLoading" />
    <div v-else>
      <div
        v-if="!keys || keys.length === 0"
        class="text-center mt-8 border-2 border-dashed mx-auto max-w-md rounded-lg p-4"
      >
        <h3 class="text-sm font-semibold text-gray-900">No Keys</h3>
        <p class="mt-1 text-sm text-gray-500">Use SSH keys to access your compute instances.</p>
        <div class="mt-6">
          <button
            class="mx-auto flex justify-center items-center text-sm font-medium text-airon-dark hover:text-black"
            @click="addKeyOpen = true"
          >
            Add a new SSH key
            <ArrowRightIcon class="h-4" />
          </button>
        </div>
      </div>
      <div v-else class="mt-4 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="table-fixed text-sm w-full divide-y divide-gray-300">
              <thead class="text-left font-semibold text-gray-900">
                <tr>
                  <th scope="col" class="px-6 py-3.5">Name</th>
                  <th scope="col" class="px-6 py-3.5">Key</th>
                  <th scope="col" class="px-6 py-3.5">Created At</th>
                  <th scope="col" class="px-6 py-3.5">
                    <span class="sr-only">Delete</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="(key, i) in keys" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap overflow-auto font-medium text-gray-900">
                    {{ key.name }}
                  </td>
                  <td class="px-6 py-4">
                    <button
                      class="text-sm text-blue-600 hover:text-blue-900"
                      type="button"
                      title="Copy key to clipboard"
                      @click="copyKey(i)"
                    >
                      {{ i === keyCopiedIdx ? 'Copied' : 'Copy' }}
                    </button>
                  </td>
                  <td class="px-6 py-4 text-gray-500">
                    {{ key.created_at || '--' }}
                  </td>
                  <td class="px-6 py-4 text-right font-medium">
                    <button class="text-red-600 hover:text-red-900" @click="openDeleteDialogue(i)">
                      Delete
                      <span class="sr-only">, {{ key.name }}</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AlertDialogue
    title="Delete Key"
    :open="deleteKeyDialogue"
    description="Are you sure you want to delete the key? It will still be present on instances deployed with the
    key."
    submit-text="Delete"
    @close="deleteKeyDialogue = false"
    @submit="deleteKey()"
  />

  <AddKeyPopoutPanel :open="addKeyOpen" @close="addKeyOpen = false" @submit="keyAdded()" />
</template>
