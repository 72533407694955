<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <rect width="12" height="12" x="11" y="11" stroke="currentColor" stroke-width="2" rx="1" />
    <rect
      width="22"
      height="22"
      x="1"
      y="1"
      stroke="currentColor"
      stroke-dasharray="3 3"
      stroke-width="2"
      rx="3"
    />
  </svg>
</template>
