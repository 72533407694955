<script setup lang="ts">
import PopoutPanel from '../components/PopoutPanel.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid'
import type { Project } from '../types/project'
import type { User } from '../types/account'
import { useProjectStore } from '../stores/project'
import { ref } from 'vue'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import { PlusIcon, ArrowRightIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import axiosClient from '../axiosClient'
import { useAuth0 } from '@auth0/auth0-vue'

const isLoading = ref<boolean>(true)
const errorMessage = ref<string>('')

const projects = ref<Project[]>([])
const projectStore = useProjectStore()

const { user } = useAuth0()

const fetchProjects = async () => {
  isLoading.value = true
  try {
    projects.value = await projectStore.getProjects()
  } catch (err) {
    if (err instanceof Error) {
      errorMessage.value = err.message
    }
  }
  isLoading.value = false
  return projects.value || []
}
fetchProjects()

const addProjectOpen = ref(false)
const selectedUsers = ref<string[]>([])

const users = ref<Array<User>>([])
axiosClient.get('user/list').then((response) => {
  const { data } = response
  users.value = data.filter((usr: User) => {
    return user.value?.sub != usr.user_id
  })
})

const newName = ref('')
const newDescription = ref('')

const createProject = () => {
  if (user.value) {
    selectedUsers.value.push(user.value.sub || "")
  }
  projectStore.addProject(newName.value, newDescription.value, selectedUsers.value).then(() => {
    addProjectOpen.value = false
    fetchProjects()
    selectedUsers.value = []
  })
}

const closeProject = (project: Project) => {
  isLoading.value = true
  const endpoint = 'project/' + project.id
  axiosClient.delete(endpoint).then(() => {
    fetchProjects()
  })
}
</script>

<template>
  <div>
    <div class="md:flex md:items-center md:justify-between border-b border-airon-border pb-4">
      <div class="min-w-0 flex-1">
        <h1
          class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
        >
          Projects
        </h1>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0">
        <button
          type="button"
          class="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          @click="addProjectOpen = true"
        >
          <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Add new project
        </button>
      </div>
    </div>

    <SpinnerLoader v-if="isLoading" />
    <div v-else>
      <ErrorMessage v-if="errorMessage.length != 0" :message="errorMessage" class="mt-4" />
      <div
        v-if="!projects || projects.length === 0"
        class="text-center mt-8 border-2 border-dashed mx-auto max-w-md rounded-lg p-4"
      >
        <h3 class="text-sm font-semibold text-gray-900">No Projects</h3>
        <p class="mt-1 text-sm text-gray-500">
          Group resources like machines, storage, billing etc. in to projects to gain overview.
        </p>
        <div class="mt-6">
          <button
            class="mx-auto flex justify-center items-center text-sm font-medium text-airon-dark hover:text-black"
            @click="addProjectOpen = true"
          >
            Add a new project
            <ArrowRightIcon class="h-4" />
          </button>
        </div>
      </div>

      <ul
        v-else
        role="list"
        class="mt-8 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 xl:gap-x-8"
      >
        <li
          v-for="project in projects"
          :key="project.id"
          class="overflow-hidden rounded-xl border border-airon-border"
        >
          <div class="flex items-center gap-x-4 border-b border-airon-border bg-gray-50 p-6">
            <RouterLink
              :to="'/projects/' + project.id"
              class="text-sm font-medium leading-6 text-gray-900"
            >
              {{ project.name }}
            </RouterLink>
            <Menu as="div" class="relative ml-auto">
              <MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span class="sr-only">Open options</span>
                <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <RouterLink
                      :to="'/projects/' + project.id"
                      :class="[
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      ]"
                    >
                      View
                      <span class="sr-only">, {{ project.name }}</span>
                    </RouterLink>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      type="button"
                      @click="closeProject(project)"
                      :class="[
                        active ? 'bg-gray-50' : '',
                        'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                      ]"
                    >
                      Delete
                      <span class="sr-only">, {{ project.name }}</span>
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <dl class="divide-y divide-airon-border px-6 py-1 text-sm leading-6">
            <div class="flex justify-between gap-x-4 py-3">
              <dt class="text-gray-500">Compute</dt>
              <dd class="text-gray-700">{{ project.compute }}</dd>
            </div>
            <div class="flex justify-between gap-x-4 py-3">
              <dt class="text-gray-500">Spend</dt>
              <dd class="flex items-start gap-x-2">
                <div class="font-medium text-gray-900">${{ project.spend.toFixed(2) }}</div>
              </dd>
            </div>
          </dl>
        </li>
      </ul>
    </div>

    <PopoutPanel
      :open="addProjectOpen"
      title="New Project"
      description="Get started by filling in the information below to create your new project."
      submit-text="Create"
      @close="addProjectOpen = false"
      @submit="createProject()"
    >
      <div>
        <label for="project-name" class="block text-sm font-medium leading-6 text-gray-900">
          Project name
        </label>
        <div class="mt-2">
          <input
            id="project-name"
            v-model="newName"
            required="true"
            type="text"
            name="project-name"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div>
        <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
          Description
        </label>
        <div class="mt-2">
          <textarea
            id="description"
            v-model="newDescription"
            name="description"
            rows="4"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div>
        <h3 class="text-sm font-medium leading-6 text-gray-900">Choose Members</h3>
        <div class="mt-2 flex flex-wrap gap-4">
          <label class="flex rounded-md bg-white border ring-1 ring-airon-dark text-sm px-2 py-1 text-gray-700 hover:cursor-not-allowed">
            {{ user?.name }}
          </label>
          <div
            v-for="user in users"
            :key="user.email"
          >
            <label class="flex rounded-md bg-white  has-[:checked]:ring-1 ring-airon-dark transition-all text-sm px-2 py-1 text-gray-700 hover:cursor-pointer hover:bg-gray-50 border">
              <input type="checkbox" class="hidden" :value="user?.user_id" v-model="selectedUsers"/>
              {{ user.name }}
            </label>
          </div>
        </div>
      </div>
    </PopoutPanel>
  </div>
</template>
