import axios from 'axios'
import { useAccountStore } from './stores/account'

const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL
})
client.interceptors.request.use((config) => {
  const accessToken = useAccountStore().accessToken
  config.headers.Authorization = 'Bearer ' + accessToken
  return config
})

export default client
