<script setup lang="ts">
import Layout from './components/Layout.vue'
import SpinnerLoader from './components/SpinnerLoader.vue'
import { useAuth0 } from '@auth0/auth0-vue'
const auth0 = useAuth0()
const isLoading = auth0.isLoading
const isAuthenticated = auth0.isAuthenticated
</script>

<template>
  <Layout v-if="!isLoading && isAuthenticated" />
  <SpinnerLoader v-else class="mx-auto min-h-screen" />
</template>
