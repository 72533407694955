<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import { ref, computed } from 'vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import axiosClient from '../axiosClient'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import { useAccountStore } from '../stores/account'
import { useRoute } from 'vue-router'

enum Choice {
  NotSelected,
  Create,
  Join
}

const choice = ref<Choice>(Choice.Create)
const organizationName = ref<string>('')
const inviteCode = ref<string>('')
const companyName = ref<string>('')

const auth0 = useAuth0()
const user = auth0.user

const isLoading = ref<boolean>(false)
const errorMessage = ref<string>('')

const accountStore = useAccountStore()

const signup = () => {
  isLoading.value = true

  let action = ''
  if (choice.value == Choice.Create) {
    action = 'create'
  } else if (choice.value == Choice.Join) {
    action = 'join'
  }

  axiosClient
    .post('user/signup', {
      action: action,
      organization: organizationName.value,
      invite_code: inviteCode.value
    })
    .then(async () => {
      await auth0.loginWithRedirect()
    })
    .catch((err) => {
      errorMessage.value = err.response.data.message
      isLoading.value = false
    })
}

const signout = () => {
  accountStore.logout()
}

let checkInviteTimeoutId: ReturnType<typeof setTimeout>
const inviteCheckIsLoading = ref<boolean>(false)

const canSignUp = computed((): boolean => {
  if (choice.value == Choice.NotSelected) return false

  if (
    choice.value == Choice.Join &&
    (companyName.value.length == 0 || inviteCheckIsLoading.value == true)
  ) {
    return false
  }

  if (choice.value == Choice.Create && organizationName.value.length < 4) {
    return false
  }

  return true
})

const checkInviteCode = () => {
  axiosClient
    .get('/user/check-invite/' + inviteCode.value)
    .then((res) => {
      companyName.value = res.data.name
      inviteCheckIsLoading.value = false
    })
    .catch(() => {
      companyName.value = ''
      inviteCheckIsLoading.value = false
    })
}
const inviteCodeChanged = () => {
  clearTimeout(checkInviteTimeoutId)
  inviteCheckIsLoading.value = true
  checkInviteTimeoutId = setTimeout(checkInviteCode, 300)
}

const route = useRoute()
const queryCode = route.query.c
if (queryCode) {
  choice.value = Choice.Join
  inviteCode.value = queryCode as string
  checkInviteCode()
}
</script>

<template>
  <div class="min-h-screen min-w-full fixed top-0 left-0 flex">
    <div class="flex min-h-full flex-1 flex-col justify-center">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <svg
          class="mx-auto h-10"
          xmlns="http://www.w3.org/2000/svg"
          width="110"
          height="33"
          fill="none"
          viewBox="0 0 110 33"
        >
          <path
            fill="#000"
            d="M105.368 9.33c-2.53 0-4.619-2.076-4.619-4.619 0-2.542 2.089-4.619 4.619-4.619 2.543 0 4.632 2.077 4.632 4.62 0 2.542-2.089 4.619-4.632 4.619Zm0-.843c2.077 0 3.789-1.699 3.789-3.776 0-2.076-1.712-3.775-3.789-3.775-2.064 0-3.776 1.699-3.776 3.775 0 2.077 1.712 3.776 3.776 3.776Zm-1.472-1.434V2.358h1.724c.881 0 1.435.579 1.435 1.36 0 .641-.378 1.069-1.032 1.233l1.724 2.102h-1.019l-1.674-2.027h-.315v2.027h-.843Zm.843-2.757h.793c.415 0 .692-.214.692-.579 0-.377-.277-.579-.692-.579h-.793v1.158ZM0 32.108 14.019 0h4.613L32.65 32.108h-5.517l-3.663-8.411H9.09l-3.618 8.41H0Zm11.125-13.115h10.31L16.28 7.1l-5.155 11.893ZM34.488 32.108V9.497h5.246v22.61h-5.246ZM44.27 32.108V9.497h4.974v2.396c1.447-1.763 3.437-2.668 5.833-2.668.634 0 1.312.046 1.9.226v5.156c-.77-.317-1.673-.452-2.623-.452-2.08 0-4.07.95-5.11 2.803v15.15h-4.975ZM82.683 32.108V9.497h4.975v2.125c1.582-1.764 3.98-2.487 6.376-2.487 5.562 0 9.135 3.844 9.135 9.632v13.34h-4.974V19.356c0-3.21-1.81-5.607-5.156-5.607-2.487 0-4.432 1.356-5.381 3.708v14.652h-4.975Z"
          />
          <path
            fill="#000"
            fill-rule="evenodd"
            d="M68.704 32.108c6.275 0 11.361-5.087 11.361-11.361 0-6.274-5.086-11.36-11.36-11.36-6.275 0-11.361 5.086-11.361 11.36 0 6.274 5.086 11.36 11.36 11.36Zm5.18-16.641a1.713 1.713 0 0 0-2.424 0l-8.002 8.002a1.713 1.713 0 0 0 2.423 2.422l8.002-8.002c.67-.669.67-1.753 0-2.422Z"
            clip-rule="evenodd"
          />
        </svg>
        <h2 class="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Complete Sign Up
        </h2>
      </div>

      <SpinnerLoader v-if="isLoading" />
      <div v-else-if="user" class="mt-10 mx-2 sm:mx-auto sm:w-full sm:max-w-sm">
        <ErrorMessage :message="errorMessage" />
        <form class="space-y-6" @submit.prevent="signup" autocomplete="off">
          <div>
            <label for="fullname" class="block text-sm font-medium leading-6 text-gray-900">
              Name
            </label>
            <div class="mt-2">
              <input
                id="name"
                type="text"
                name="name"
                :value="user.name"
                disabled="true"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200 sm:text-sm sm:leading-6"
                :placeholder="user.name"
              />
            </div>
          </div>

          <div>
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div class="mt-2">
              <input
                id="email"
                type="email"
                name="email"
                :value="user.email"
                disabled="true"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 cursor-not-allowed bg-gray-50 text-gray-500 ring-gray-200 sm:text-sm sm:leading-6"
                :placeholder="user.email"
              />
            </div>
          </div>

          <div v-if="choice == Choice.NotSelected">
            <h3 class="block text-sm font-medium leading-6 text-gray-900">
              Company / Organization
            </h3>
            <div class="grid grid-cols-2 gap-2">
              <button
                type="button"
                class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="choice = Choice.Create"
              >
                Create
              </button>

              <button
                type="button"
                class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="choice = Choice.Join"
              >
                Connect
              </button>
            </div>
          </div>

          <div v-else class="mt-4 text-sm text-gray-600" v-if="choice == Choice.Join">
            <label for="code" class="block text-sm font-medium leading-6 text-gray-900">
              Connect - Invite Code
            </label>
            <p class="text-gray-400 text-xs">
              The invite code is extracted from your invite link. Make sure the organization name
              displayed is correct before joining.
            </p>
            <div class="flex mt-2">
              <input
                v-model="inviteCode"
                @input="inviteCodeChanged()"
                type="password"
                name="code"
                id="code"
                class="block w-full rounded-y-md rounded-l-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                placeholder=""
                autocomplete="off"
                autocorrect="off"
                aria-describedby="invite-code"
              />

              <div
                class="whitespace-nowrap border-y border-r border-gray-300 text-gray-500 bg-gray-50 font-medium text-xs flex items-center rounded-r-md px-2"
              >
                <span v-if="inviteCheckIsLoading">Verifying...</span>
                <span v-else>
                  <span v-if="companyName.length > 0">
                    {{ companyName }}
                  </span>
                  <span v-else class="text-red-500">Invalid Code</span>
                </span>
              </div>
            </div>
          </div>

          <div class="mt-4 text-sm text-gray-600" v-if="choice == Choice.Create">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
              Company / Organization name
            </label>
            <div class="mt-2">
              <input
                v-model="organizationName"
                type="text"
                name="name"
                id="name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                placeholder=""
                aria-describedby="company-name"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="flex w-full justify-center rounded-md bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:cursor-not-allowed disabled:bg-gray-400"
              :disabled="!canSignUp"
            >
              Sign Up
            </button>
          </div>
        </form>

        <p class="mt-10 text-center text-sm text-gray-500">
          Switch Account?
          {{ ' ' }}
          <button
            type="button"
            class="font-semibold leading-6 text-gray-600 hover:text-gray-500"
            @click="signout"
          >
            Sign Out
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
