<script setup lang="ts">
import type { AironNotification } from '../types/account.d.ts'
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  CheckCircleIcon
} from '@heroicons/vue/20/solid'

defineProps<{ data: AironNotification }>()

interface Style {
  icon: any
  mainStyle: string
  iconStyle: string
  linkStyle: string
}
interface Styles {
  [index: string]: Style
}

const notificationStyles = {
  info: {
    icon: InformationCircleIcon,
    mainStyle: 'bg-white text-airon-dark',
    iconStyle: 'text-black',
    linkStyle: 'hover:text-black'
  },
  warn: {
    icon: ExclamationTriangleIcon,
    mainStyle: 'bg-white text-airon-dark',
    iconStyle: 'text-black',
    linkStyle: 'hover:text-black'
  },
  error: {
    icon: XCircleIcon,
    mainStyle: 'bg-white text-airon-dark',
    iconStyle: 'text-black',
    linkStyle: 'hover:text-black'
  },
  success: {
    icon: CheckCircleIcon,
    mainStyle: 'bg-white text-airon-dark',
    iconStyle: 'text-black',
    linkStyle: 'hover:text-black'
  }
} as Styles
</script>

<template>
  <div
    v-if="data && data.message"
    :class="['flex rounded-md p-4 w-full', notificationStyles[data.type].mainStyle]"
  >
    <div class="flex-shrink-0">
      <component
        :is="notificationStyles[data.type].icon"
        :class="['h-5 w-5', notificationStyles[data.type].iconStyle]"
        aria-hidden="true"
      />
    </div>
    <div class="ml-3 flex-1 md:flex md:justify-between">
      <p class="text-sm">{{ data.message }}</p>
      <p class="mt-3 text-sm md:mt-0">
        <RouterLink
          v-if="data.link_to != $route.path"
          :to="data.link_to"
          :class="['whitespace-nowrap font-medium', notificationStyles[data.type].linkStyle]"
        >
          {{ data.link_text }}
          <span aria-hidden="true">&rarr;</span>
        </RouterLink>
      </p>
    </div>
  </div>
</template>
