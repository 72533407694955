<script lang="ts" setup>
import StatusDefaultIcon from '@/assets/icons/status-default.svg?component'
import StatusRunningIcon from '@/assets/icons/status-running.svg?component'

defineProps({
  status: {
    type: String,
    required: true
  }
})

const state = {
  deploying: StatusDefaultIcon,
  deployed: StatusRunningIcon
} as any
</script>

<template>
  <component :is="state[status] || StatusDefaultIcon" />
</template>
