import { authGuard } from '@auth0/auth0-vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useAccountStore } from '../stores/account'
import { useGlobalStore } from '../stores/global'
import APIView from '../views/APIView.vue'
import BillingView from '../views/BillingView.vue'
import ComputeListView from '../views/ComputeListView.vue'
import StartComputeView from '../views/StartComputeView.vue'
import DocsView from '../views/DocsView.vue'
import ImagesView from '../views/ImagesView.vue'
import KeysView from '../views/KeysView.vue'
import UsageView from '../views/UsageView.vue'
import NetworkView from '../views/NetworkView.vue'
import ProjectListView from '../views/ProjectListView.vue'
import ProjectView from '../views/ProjectView.vue'
import SettingsView from '../views/SettingsView.vue'
import SignupView from '../views/SignupView.vue'
import StorageView from '../views/StorageView.vue'
import SupportView from '../views/SupportView.vue'
import UserView from '../views/UserView.vue'
import UsersListView from '../views/UsersListView.vue'
import PricingView from '../views/PricingView.vue'
import NotFoundView from '../views/NotFoundView.vue'

import AdminView from '../views/AdminView.vue'

import type { Organization } from '@/types/account'
import { useAuth0 } from '@auth0/auth0-vue'
import type { AxiosResponse } from 'axios'
import axiosClient from '../axiosClient'
import EventLogView from '@/views/EventLogView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/signup',
      name: 'signup',
      component: SignupView,
      meta: {
        title: 'Sign Up',
        hideMenu: true
      }
    },
    {
      path: '/',
      name: 'home',
      component: ComputeListView,
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: PricingView,
      meta: {
        title: 'Pricing'
      }
    },
    {
      path: '/compute',
      name: 'compute',
      component: ComputeListView,
      meta: {
        title: 'Compute'
      }
    },
    {
      path: '/compute/start',
      name: 'start compute',
      component: StartComputeView,
      meta: {
        title: 'Start Compute'
      }
    },
    {
      path: '/storage',
      name: 'storage',
      component: StorageView,
      meta: {
        title: 'Storage'
      }
    },
    {
      path: '/keys',
      name: 'keys',
      component: KeysView,
      meta: {
        title: 'Keys'
      }
    },
    {
      path: '/billing',
      name: 'billing',
      component: BillingView,
      meta: {
        title: 'Billing'
      }
    },
    {
      path: '/users',
      name: 'users',
      component: UsersListView,
      meta: {
        title: 'Users'
      }
    },
    {
      path: '/user/:id',
      name: 'user',
      component: UserView,
      meta: {
        title: 'User'
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: SettingsView,
      meta: {
        title: 'Settings'
      }
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectListView,
      meta: {
        title: 'Projects'
      }
    },
    {
      path: '/projects/:id',
      name: 'Project',
      component: ProjectView,
      meta: {
        title: 'Project'
      }
    },
    {
      path: '/usage',
      name: 'usage',
      component: UsageView,
      meta: {
        title: 'Usage'
      }
    },
    {
      path: '/support',
      name: 'support',
      component: SupportView,
      meta: {
        title: 'Support'
      }
    },
    {
      path: '/network',
      name: 'network',
      component: NetworkView,
      meta: {
        title: 'Network'
      }
    },
    {
      path: '/images',
      name: 'images',
      component: ImagesView,
      meta: {
        title: 'Images'
      }
    },
    {
      path: '/api',
      name: 'api',
      component: APIView,
      meta: {
        title: 'API'
      }
    },
    {
      path: '/docs',
      name: 'docs',
      component: DocsView,
      meta: {
        title: 'Docs'
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminView,
      meta: {
        title: 'Admin',
        hideMenu: true
      }
    },
    {
      path: '/admin/log',	
      name: 'admin log',
      component: EventLogView,
      meta: {
        title: 'Event Log',
        hideMenu: true
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: NotFoundView,
      meta: {
        title: '404 - Not found'
      }
    }
  ]
})

router.beforeEach(authGuard)

router.beforeEach((to, _from, next) => {
  window.document.title = (to.meta.title as string) + ' - Airon'

  if (to.name == 'admin') {
    const head = document.querySelector('head')
    const livesession = head?.querySelector('#livesession')
    if (head && livesession) {
      head.removeChild(livesession)
    }
  }
  next()
})

interface LoginResponse {
  redirect: boolean
  organization: Organization
}

router.beforeEach(async (to, _from, next) => {
  const auth0 = useAuth0()

  const accountStore = useAccountStore()
  accountStore.accessToken = (await auth0.getAccessTokenSilently({})) as string

  if (to.name == 'signup') {
    return next()
  }

  if (accountStore.organization == null) {
    const response = (await axiosClient.post('user/spalogin').catch(() => {
      // logout? redirect?
    })) as AxiosResponse<LoginResponse>

    if (response.data.redirect) {
      return next({
        name: 'signup'
      })
    } else {
      accountStore.organization = response.data.organization
    }
  }

  const global = useGlobalStore()
  global.fetchNotifications()

  next()
})

export default router
