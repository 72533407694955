<script setup lang="ts">
import { ref } from 'vue'
import axiosClient from '../axiosClient'
import NotificationCard from '@/components/NotificationCard.vue';
import type { ModuleSource } from 'module';

interface Pool {
  id: number
  name: string
  description: string
  machine_type: number

  gpu: string
  type: string
  cpu: string
  ram: string
  storage: string

  avail_machine_ids: string[]
  current_hr_price: number
  did_reserve: boolean
}

interface Location {
  id: number
  name: string
  avail_machine_ids: string[]
  num_machines_in_selected_pool: number

  is_selected: boolean
}

interface Reservation {
  id: number
  org_id: number
  pool_id: number
  time: string
}

const pools = ref<Pool[]>([])
const locations = ref<Location[]>([])
const reservations = ref<number[]>([])

const successMessage = ref<string>('')
const infoMessage = ref<string>('')
const errorMessage = ref<string>('')

const userReservations = () => {
  axiosClient
    .get('/user/reservations')
    .then((res) => {
      //for each reservation, add pool id to r
      res.data.forEach((reservation: Reservation) => {
        reservations.value.push(reservation.pool_id)
      })
      console.log(reservations)
    })
    .catch((err) => {
      console.log(err)
    })
}

userReservations();

const reserve = (pool: Pool) => {
  //modal
  if (confirm(
    `Would you like to reserve ${pool.name} and be put on a waiting list?`
  )) {
    axiosClient
    .post('/machine/reserve/'+pool.id)
    .then((res) => {
      successMessage.value = "Reservation successful"
      userReservations()
    })
    .catch((err) => {
      console.log(err)
      errorMessage.value = "Reservation failed. Contact support" 
    })
  }
}

const fetchOverview = async () => {
  const response = await axiosClient.get('machine/overview')

  const { data } = response
  locations.value = data.locations
  pools.value = data.pools
  console.log(data)
}
fetchOverview()
</script>

<template>
  <div class="border-b border-airon-border pb-4">
    <h1 class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
      Pricing
    </h1>
  </div>
  <div>
    <NotificationCard class="mt-8" :data="{
      type: 'success',
      message: successMessage,
      link_text: '',
      link_to: ''
    }" />
    <NotificationCard class="mt-8" :data="{
      type: 'info',
      message: infoMessage,
      link_text: '',
      link_to: ''
    }" />
    <NotificationCard class="mt-8" :data="{
      type: 'error',
      message: errorMessage,
      link_text: '',
      link_to: ''
    }" />
  </div>
  <div class="mt-4 flow-root">
    <div class="-mx-6 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table class="min-w-full divide-y divide-airon-border">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left font-semibold sm:pl-0">
                Bare metal GPU resource
                <!-- TODO separate CPU and GPU -->
              </th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">GPU</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">Type</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">CPU</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">RAM</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">Storage</th>
              <th scope="col" class="px-3 py-3.5 text-left font-semibold">Price</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Action</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-airon-border">
            <tr v-for="pool in pools" key="{i}">
              <td class="whitespace-nowrap px-3 py-4 sm:pl-0">
                {{ pool.name }}
                <span v-if="pool.machine_type == 2"
                  class="text-sm ring-1 ring-gray-400 bg-gray-100 text-gray-500 rounded-sm px-2 py-1 ml-2">
                  General Compute
                </span>
              </td>
              <td class="whitespace-nowrap px-3 py-4">{{ pool.gpu }}</td>
              <td class="whitespace-nowrap px-3 py-4">{{ pool.type }}</td>

              <td class="whitespace-nowrap px-3 py-4">{{ pool.cpu }}</td>
              <td class="whitespace-nowrap px-3 py-4">
                {{ pool.ram }}
              </td>
              <td class="whitespace-nowrap px-3 py-4">{{ pool.storage }}</td>
              <td class="whitespace-nowrap px-3 py-4">
                {{ pool.current_hr_price > 0 ? `$${pool.current_hr_price}/h` : '' }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-right">
                <RouterLink v-if="pool.avail_machine_ids != null" :to="'/compute/start/?p=' + pool.id"
                  class="font-semibold">
                  Deploy
                </RouterLink>
                <!-- TODO indicate if already reserved -->
                <div v-else>
                  <span v-if="reservations.includes(pool.id)" class="cursor-default">Reserved</span>
                  <button v-else @click="reserve(pool)" class="font-semibold">Reserve</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
