<script setup lang="ts">
import { useAccountStore } from '../stores/account'

const accountStore = useAccountStore()
const org = accountStore.organization

</script>

<template>
  <div>
    <p>
      If you have any questions, please contact us via email.
    </p>
    <div class="w-fit border border-airon-border rounded-md py-2 px-4 my-2">
      <span class="font-bold">IMPORTANT:</span>
      Please identify yourself by specifying your organization name.
      <p class="my-1" v-if="org">
        Name: <span class="font-medium bg-gray-300 text-gray-700 px-1">{{org.name}}</span>
      </p>
    </div>
    <a href="mailto:support@airon.ai" class="underline">support@airon.ai</a>
  </div>
</template>
