<script setup lang="ts">
import { ref, computed } from 'vue'
import axiosClient from '../axiosClient'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import ErrorMessage from '../components/ErrorMessage.vue'

interface MachineSession {
  status: string
  type: string
  started_by: string
  started_at: string
  stopped_at: string
  duration: number
  hr_price: number
  spend: number
}

const isLoading = ref<boolean>(true)
const errorMessage = ref<string>('')

const balance = ref<number>(0.0)
const totalSpend = ref<number>(0.0)
const usage = ref<number>(0.0)
const timeRemaining = ref<string>('--')
const sessions = ref<MachineSession[]>([])
const page_index = ref<number>(0)
const num_pages = ref<number>(0)

const getUsage = () => {
  axiosClient
    .get('/monitor/usage?page=' + page_index.value)
    .then((response) => {
      balance.value = response.data.balance
      totalSpend.value = response.data.total_spend
      usage.value = response.data.usage
      timeRemaining.value = response.data.time_remaining

      if (response.data.sessions) {
        num_pages.value = response.data.num_pages
        sessions.value = response.data.sessions
          .sort((a: MachineSession) => (a.status == 'Running' ? -1 : 0))
      }

      isLoading.value = false
    })
    .catch((err) => {
      if (err.response) {
        errorMessage.value = err.response.data.message
      } else {
        errorMessage.value = err.message
      }
      isLoading.value = false
    })
}
getUsage()

const changePage = (amount: number) => {
  page_index.value += amount
  page_index.value = Math.min(num_pages.value - 1, Math.max(page_index.value, 0))
  getUsage()
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const stats = computed(() => [
  { name: 'Balance', value: formatter.format(balance.value) },
  { name: 'Total Spend', value: formatter.format(totalSpend.value) },
  { name: 'Usage', value: formatter.format(usage.value) + '/h' },
  { name: 'Estimated time remaining', value: timeRemaining.value }
])
</script>

<template>
  <div>
    <div class="border-b border-airon-border pb-4">
      <h1 class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        Usage
      </h1>
    </div>

    <SpinnerLoader v-if="isLoading" />
    <div v-else>
      <ErrorMessage v-if="errorMessage.length > 0" :message="errorMessage" class="mt-4" />
      <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 border-b">
        <div v-for="stat in stats" :key="stat.name"
          class="flex flex-wrap items-baseline justify-between gap-x-4 bg-airon-lighter gap-y-2 px-4 py-10 sm:px-6 xl:px-8">
          <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
          <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {{ stat.value }}
          </dd>
        </div>
      </dl>


      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="sessions && sessions.length > 0">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="table-auto text-sm w-full divide-y divide-gray-300 mt-8">
            <thead class="text-left font-semibold text-gray-900">
              <tr>
                <th scope="col" class="px-4 py-3.5">Compute</th>
                <th scope="col" class="px-4 py-3.5">Type</th>
                <th scope="col" class="px-4 py-3.5">Started By</th>
                <th scope="col" class="px-4 py-3.5">Started</th>
                <th scope="col" class="px-4 py-3.5">Stopped</th>
                <th scope="col" class="px-4 py-3.5">Duration</th>
                <th scope="col" class="px-4 py-3.5">Rate</th>
                <th scope="col" class="px-4 py-3.5">Spend</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="(ses, i) in sessions" :key="i">
                <td class="px-4 py-4 whitespace-nowrap overflow-auto font-medium text-gray-900">
                  <div v-if="ses.status == 'Running'">
                    <span
                      class="mr-2 text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                      Running
                    </span>
                  </div>
                  <span v-else
                    class="text-gray-700 bg-gray-50 ring-gray-600/20 rounded-md whitespace-nowrap px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                    Stopped
                  </span>
                </td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">{{ ses.type }}</td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">{{ ses.started_by }}</td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">{{ ses.started_at }}</td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">{{ ses.stopped_at }}</td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">
                  {{ ses.duration.toFixed(1) }}h
                </td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">${{ ses.hr_price }}/h</td>
                <td class="px-4 py-4 whitespace-nowrap overflow-auto">
                  ${{ ses.spend.toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-between items-center border-gray-300 px-4 pt-2" v-if="true">
        <p class="text-sm font-medium">Page {{ page_index + 1 }} of {{ num_pages }}</p>
        <div class="">
          <button
            class="bg-white font-bold outline outline-1 outline-gray-300 hover:bg-gray-50 px-4 py-0.5 rounded-md text-sm transition-all mr-2 disabled:bg-gray-200 disabled:text-gray-500"
            :disabled="page_index == 0"
            @click="changePage(-1)">&lt; Previous</button>
          <button
            class="bg-white font-bold outline outline-1 outline-gray-300 hover:bg-gray-50 px-4 py-0.5 rounded-md text-sm transition-all mr-2 disabled:bg-gray-200 disabled:text-gray-500"
            :disabled="page_index == (num_pages - 1)"
            @click="changePage(1)">Next &gt;</button>
        </div>
      </div>

    </div>
  </div>
</template>
