<script setup lang="ts">
import { ref, computed, onUnmounted } from 'vue'
import prettyBytes from 'pretty-bytes'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import StatusIcon from '../components/StatusIcon.vue'
import FlagIcon from '../components/FlagIcon.vue'
import type { Machine } from '../types/machine'
import axiosClient from '../axiosClient'

import AlertDialogue from '../components/AlertDialogue.vue'
import { PlusIcon, ArrowRightIcon, ArrowPathIcon } from '@heroicons/vue/20/solid'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline'

const isLoading = ref<boolean>(true)
const errorMessage = ref<string>('')

const machines = ref<Machine[]>([])

const releaseMachineDialogue = ref<boolean>(false)
const machineToReleaseIdx = ref<number>(-1)

const renameMachineDialogue = ref<boolean>(false)
const machineToRenameIdx = ref<number>(-1)
const newName = ref<string>('')

const restartMachineDialogue = ref<boolean>(false)
const machineToRestartIdx = ref<number>(-1)

let statusIntervalId: ReturnType<typeof setInterval>
const syncStatus = () => {
  let isDone = true
  if (machines.value === null) {
    return
  }
  machines.value.map((machine) => {
    if (machine.status_name != 'Deployed') {
      isDone = false
      axiosClient
        .get('/machine/' + machine.system_id + '/status')
        .then((res) => {
          machine.status_name = res.data.status_name
          machine.ip_addresses = res.data.ips
          machine.power_state = res.data.power_state
        })
        .catch((err) => (errorMessage.value = err.response.data.message))
    }
  })

  if (isDone) {
    clearInterval(statusIntervalId)
  }
}

onUnmounted(() => {
  clearInterval(statusIntervalId)
})

const fetchMachines = async () => {
  isLoading.value = true
  axiosClient
    .get('machine/list')
    .then((res) => {
      machines.value = res.data
      isLoading.value = false

      clearInterval(statusIntervalId)
      statusIntervalId = setInterval(syncStatus, 30000)
    })
    .catch((err) => {
      if (err.response) {
        errorMessage.value = err.response.data.message
      } else {
        errorMessage.value = err.message
      }
      isLoading.value = false
    })
}

fetchMachines()

const restartCompute = () => {
  axiosClient
    .post('machine/' + machines.value[machineToRestartIdx.value].system_id, { action: 'restart' })
    .then(() => {
      restartMachineDialogue.value = false
      fetchMachines()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const releaseCompute = () => {
  const idx = machineToReleaseIdx.value
  machineToReleaseIdx.value = -1
  releaseMachineDialogue.value = false

  const machine = machines.value[idx]
  axiosClient
    .post('machine/' + machine.system_id, { action: 'release' })
    .then(() => {
      machines.value.splice(idx, 1)
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const renameCompute = () => {
  const machine = machines.value[machineToRenameIdx.value]
  axiosClient
    .post('machine/' + machine.system_id + '/rename', {
      name: newName.value
    })
    .then(() => {
      fetchMachines()
      renameMachineDialogue.value = false
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const ipCopiedIdx = ref(-1)
const copyIP = (i: number) => {
  const txt = 'ubuntu@' + machines.value[i].ip_addresses.join(' / ')
  navigator.clipboard.writeText(txt)
  ipCopiedIdx.value = i
  setTimeout(() => (ipCopiedIdx.value = -1), 1000)
}

const formatBase1024 = (bytes: number) => {
  var sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB']
  if (bytes == 0) return 'n/a'
  var i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i == 0) return bytes + ' ' + sizes[i]
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
}

// const calculateOverlap = () => { TODO: Move into start compute
//   const pool = selectedPool.value

//   if (pool != null) {
//     for (let i = 0; i < locations.value.length; ++i) {
//       const loc = locations.value[i]

//       let numOverlappingMachines = 0
//       for (let j = 0; j < pool.avail_machine_ids?.length; ++j) {
//         for (let k = 0; k < loc.avail_machine_ids?.length; ++k) {
//           if (pool.avail_machine_ids[j] == loc.avail_machine_ids[k]) {
//             numOverlappingMachines += 1
//           }
//         }
//       }

//       loc.num_machines_in_selected_pool = numOverlappingMachines
//     }
//   }
// }
</script>

<template>
  <div>
    <div class="md:flex md:items-center md:justify-between border-b border-airon-border pb-4">
      <div class="min-w-0 flex-1">
        <h1
          class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
        >
          Compute Instances
        </h1>
      </div>
      <div class="mt-4 flex md:ml-4 md:mt-0">
        <RouterLink
          to="/compute/start"
          class="inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
        >
          <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Start Compute
        </RouterLink>
      </div>
    </div>

    <ErrorMessage :message="errorMessage" class="mt-4" />

    <div class="mt-2">
      <div
        v-if="!isLoading && machines && machines.length > 0"
        class="-mx-4 sm:-mx-6 lg:-mx-8 overflow-x-auto"
      >
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="table-auto text-sm w-full divide-y divide-gray-300">
            <thead class="text-left text-gray-900">
              <tr>
                <th scope="col" class="font-normal px-4 py-3.5">Compute</th>
                <th scope="col" class="font-normal px-4 py-3.5">Type</th>
                <th scope="col" class="font-normal px-4 py-3.5">Region DC</th>
                <th scope="col" class="font-normal px-4 py-3.5">CPU</th>
                <th scope="col" class="font-normal px-4 py-3.5">RAM</th>
                <th scope="col" class="font-normal px-4 py-3.5">Total Storage</th>
                <th scope="col" class="font-normal px-4 py-3.5">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(machine, i) in machines"
                :key="i"
                :class="{ 'border border-red-500': machine.status_name == 'Maintenance' }"
              >
                <td class="px-4 py-3 font-medium text-gray-900">
                  <div>
                    <div
                      :title="machine.status_name"
                      class="flex items-center gap-2 whitespace-nowrap"
                    >
                      <StatusIcon
                        status="deployed"
                        :class="{
                          'left-0 h-4  fill-gray-500': true,
                          'animate-spin-slow': machine.status_name == 'Deploying'
                        }"
                      />
                      <div class="flex gap-2">
                        <span>{{ machine.name }}</span>
                        <PencilSquareIcon
                          class="h-5 transition-opacity hover:cursor-pointer opacity-10 hover:opacity-50"
                          @click="(machineToRenameIdx = i), (renameMachineDialogue = true)"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="machine.power_state == 'error'" class="text-xs mt-1 text-red-500">
                    Power {{ machine.power_state }}
                  </div>
                  <div v-else>
                    <button
                      v-if="machine.status_name == 'Deployed'"
                      type="button"
                      title="Copy to clipboard"
                      class="flex items-center gap-1 text-xs font-normal mt-1 opacity-60 hover:opacity-90"
                      @click="copyIP(i)"
                    >
                      <span v-if="ipCopiedIdx == i">copied to clipboard</span>
                      <span v-else>ubuntu@{{ machine.ip_addresses.join(' / ') }}</span>
                    </button>
                  </div>
                </td>
                <td class="px-4 py-3">
                  <div
                    class="w-fit border border-zinc-300 text-xs py-1 px-2 rounded-md font-medium"
                  >
                    {{ machine.pool.name }}
                  </div>
                </td>
                <td class="px-4 py-3">
                  <div class="flex items-center gap-1">
                    <FlagIcon class="h-3" country="SE" />
                    <span class="opacity-60 whitespace-nowrap">{{ machine.location.name }}</span>
                  </div>
                </td>
                <td class="px-4 py-3 opacity-60 whitespace-nowrap">
                  {{ machine.cpu_count }} x {{ machine.cpu_speed / 1000 + ' GHz' || '--' }}
                </td>
                <td class="px-4 py-3 opacity-60 whitespace-nowrap">
                  {{ formatBase1024(machine.ram) || '--' }}
                </td>
                <td class="px-4 py-3 opacity-60 whitespace-nowrap">
                  {{ prettyBytes(machine.storage) || '--' }}
                </td>
                <td class="px-4 py-3 whitespace-nowrap space-x-2">
                  <button
                    v-if="machine.status_name == 'Deployed'"
                    class="group text-gray-500 border rounded-md bg-gray-50 transition-all hover:border-gray-500 hover:cursor-pointer hover:text-gray-700 hover:bg-gray-100"
                    @click="(machineToRestartIdx = i), (restartMachineDialogue = true)"
                  >
                    <ArrowPathIcon class="group-hover:rotate-45 transition-all h-8 p-1.5" />
                  </button>

                  <button
                    class="group text-red-400 border border-red-200 rounded-md bg-red-50 transition-all hover:border-red-600 hover:cursor-pointer hover:text-red-50 hover:bg-red-500"
                    @click="(machineToReleaseIdx = i), (releaseMachineDialogue = true)"
                  >
                    <TrashIcon class="h-8 p-1.5" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <SpinnerLoader v-else-if="isLoading" />
      <div
        v-if="!isLoading && (!machines || machines.length === 0)"
        class="text-center mt-8 border-2 border-dashed mx-auto max-w-md rounded-lg p-4"
      >
        <h3 class="text-sm font-semibold text-gray-900">No Instances</h3>
        <p class="mt-1 text-sm text-gray-500">Get started by launching a new compute instance.</p>
        <div class="mt-6 flex items-center justify-center">
          <RouterLink
            to="/compute/start"
            class="flex justify-center items-center text-sm font-medium text-airon-dark hover:text-black"
          >
            Launch an Instance
            <ArrowRightIcon class="h-4" />
          </RouterLink>
        </div>
      </div>
    </div>
  </div>

  <AlertDialogue
    v-if="machineToReleaseIdx >= 0"
    :title="'Terminate ' + machines[machineToReleaseIdx]?.name"
    description="Are you sure you want to terminate this compute instance? You will lose access and all local storage will be
                        erased."
    :open="releaseMachineDialogue"
    submit-text="Terminate"
    @close="releaseMachineDialogue = false"
    @submit="releaseCompute()"
  />

  <AlertDialogue
    v-if="machineToRestartIdx >= 0"
    type="warning"
    :title="'Reboot ' + machines[machineToRestartIdx]?.name"
    description="Are you sure you want to restart this compute instance? The compute instance will be unavailable for up to 5 minutes.
      Additional time may be required for the operating system to boot."
    :open="restartMachineDialogue"
    submit-text="Reboot"
    @close="restartMachineDialogue = false"
    @submit="restartCompute()"
  />

  <AlertDialogue
    v-if="machineToRenameIdx >= 0"
    type="info"
    :title="'Rename ' + machines[machineToRenameIdx]?.name"
    description="Change the visual name of your compute instance. This will not change the hostname."
    :open="renameMachineDialogue"
    submit-text="Rename"
    @close="renameMachineDialogue = false"
    @submit="renameCompute()"
  >
    <p class="mt-2 text-gray-700">Deployed as: {{ machines[machineToRenameIdx]?.hostname }}</p>
    <label for="name" class="mt-1 block text-sm font-medium leading-6 text-gray-900">
      New Name
    </label>
    <div class="mt-1 w-full">
      <input
        v-model="newName"
        type="text"
        name="name"
        id="name"
        class="block w-full outline-0 rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
        placeholder="bright-bengal"
      />
    </div>
  </AlertDialogue>
</template>
