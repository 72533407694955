<script setup lang="ts">
import { ref } from 'vue'
import { useAccountStore } from '../stores/account'
import axiosClient from '../axiosClient'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import { useAuth0 } from '@auth0/auth0-vue'

const accountStore = useAccountStore()
const { user } = useAuth0()

const closeAccountDialogue = ref(false)
const errorMessage = ref('')

const balance = ref(0)

const closeAccount = () => {
  axiosClient.post('user/delete').then((res) => {
    if (res.status === 200) {
      accountStore.logout()
    }
  }).catch((err) => {
    console.log(err.response.data.message)
    errorMessage.value = err.response.data.message
  })
}

axiosClient.get('/monitor/usage').then((response) => {
  balance.value = response.data.balance
})


const getUserInfo = () => {
  location.reload()
}

const passwordForm = ref({
  newPassword: '',
  confirmPassword: ''
})
const changePasswordMessage = ref('')
const changePassword = () => {
  if (
    passwordForm.value.newPassword == '' ||
    passwordForm.value.newPassword != passwordForm.value.confirmPassword
  ) {
    changePasswordMessage.value = 'The passwords did not match'
    window.scrollTo({ top: 0, behavior: 'smooth' })
    return
  }
  axiosClient
    .post('user/change-password', { password: passwordForm.value.newPassword })
    .then(() => {
      accountStore.logout()
    })
}

const profileForm = ref({
  name: user.value?.name,
  nickname: user.value?.nickname
})
const saveProfile = () => {
  axiosClient.post('user/save-profile', profileForm.value).then(() => {
    location.reload()
  })
}
</script>

<template>
  <div v-if="!user?.email_verified" class="rounded-md bg-red-50 p-4 mb-3">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3 flex-1 flex justify-between">
        <h3 class="text-sm font-medium text-red-800">Email not verified, check your inbox.</h3>
        <span class="text-sm underline text-red-500 hover:cursor-pointer" @click="getUserInfo">
          Refresh
        </span>
      </div>
    </div>
  </div>
  <div v-if="changePasswordMessage != ''" class="rounded-md bg-yellow-50 p-4 mb-3">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800">Change password</h3>
        <div class="mt-2 text-sm text-yellow-700">
          <p>{{ changePasswordMessage }}</p>
        </div>
      </div>
    </div>
  </div>
  <div v-if="user">
    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 border-b !border-airon-border"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">
          <span v-if="user.sub?.startsWith('auth0|')">Information obtained from auth0.</span>
          <span v-else-if="user.sub?.startsWith('windowslive')">
            Information obtained from your Microsoft account.
          </span>
          <span v-else-if="user.sub?.startsWith('google')">
            Information obtained from google. You can change it
            <a
              href="https://myaccount.google.com/personal-info"
              target="_blank"
              class="text-blue-500 underline hover:no-underline"
            >
              here
            </a>
          </span>
          <span v-else>Information from external provider.</span>
        </p>
      </div>

      <form class="md:col-span-2" @submit.prevent="saveProfile">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div class="col-span-full flex items-center gap-x-8">
            <img
              :src="user?.picture || '/image-placeholder.jpg'"
              alt=""
              class="h-24 w-24 flex-none rounded-lg bg-gray-100 object-cover"
              loading="eager"
            />
            <div v-if="false">
              <button
                type="button"
                class="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white/20"
              >
                Change avatar
              </button>
              <p class="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
            <div class="mt-2">
              <input
                id="name"
                v-model="profileForm.name"
                type="text"
                name="name"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-50 text-gray-500 ring-gray-200 focus:ring-gray-500 sm:text-sm sm:leading-6"
                :disabled="!user.sub?.startsWith('auth0|')"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="nickname" class="block text-sm font-medium leading-6 text-gray-900">
              Nickname
            </label>
            <div class="mt-2">
              <input
                id="nickname"
                v-model="profileForm.nickname"
                type="text"
                name="nickname"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-50 text-gray-500 ring-gray-200 focus:ring-gray-500 sm:text-sm sm:leading-6"
                :disabled="!user.sub?.startsWith('auth0|')"
              />
            </div>
          </div>

          <div class="col-span-full">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div class="mt-2">
              <input
                id="email"
                :value="user?.email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 placeholder:text-gray-400 disabled:cursor-not-allowed disabled:bg-gray-50 text-gray-500 ring-gray-200 focus:ring-gray-500 sm:text-sm sm:leading-6"
                :disabled="true"
              />
            </div>
          </div>
        </div>

        <div class="mt-8 flex items-center gap-4">
          <button
            type="submit"
            class="rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm enabled:hover:bg-gray-700 disabled:cursor-not-allowed disabled:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            :disabled="!user.sub?.startsWith('auth0|')"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div
      v-if="user.sub?.startsWith('auth0|')"
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8 border-b !border-airon-border"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Change password</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">
          Update your password associated with your account.
        </p>
      </div>

      <form class="md:col-span-2" @submit.prevent="changePassword">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div class="col-span-full">
            <label for="new-password" class="block text-sm font-medium leading-6 text-gray-900">
              New password
            </label>
            <div class="mt-2">
              <input
                id="new-password"
                v-model="passwordForm.newPassword"
                type="password"
                autocomplete="new-password"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>

          <div class="col-span-full">
            <label for="confirm-password" class="block text-sm font-medium leading-6 text-gray-900">
              Confirm password
            </label>
            <div class="mt-2">
              <input
                id="confirm-password"
                v-model="passwordForm.confirmPassword"
                type="password"
                autocomplete="new-password"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                required
              />
            </div>
          </div>
        </div>

        <div class="mt-8 flex">
          <button
            type="submit"
            class="rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Disable account</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">
          No longer want to use our service? You can disable your account here. This action is not
          reversible. 
        </p>
      </div>

      <div class="flex items-start md:col-span-2">
        <button
          type="button"
          class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
          @click="closeAccountDialogue = true"
        >
          Yes, disable my account
        </button>
      </div>
    </div>

    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <div>
      <h2 class="text-base font-semibold leading-7 text-gray-900">Questions?</h2>

        <p class="mt-1 text-sm leading-6 text-gray-500">
          Don't hesitate to contact us at <a href="mailto:support@airon.ai" class="underline">support@airon.ai</a>.
        </p>
      </div>
    </div>
  </div>
  <SpinnerLoader v-else />
  <TransitionRoot as="template" :show="closeAccountDialogue">
    <Dialog as="div" class="relative z-10" @close="closeAccountDialogue = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    Disable account
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Are you sure you want to close your account? This action cannot be undone.
                      <br>
                      <strong class="text-red-600">Remaining balance: ${{ balance }} USD </strong>
                    </p>
                    <span v-if="errorMessage != ''">
                      <br>
                      <strong class="text-red-600">Error: {{ errorMessage }}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  @click="closeAccount()"
                >
                  Disable account
                </button>
                <button
                  ref="cancelButtonRef"
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  @click="closeAccountDialogue = false"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
