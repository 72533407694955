import type { Organization } from '@/types/account'

import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import axiosClient from '../axiosClient'
import { auth0 } from '../auth0'

const jsonSerializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v)
}

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    organization: useStorage<Organization | null>('account.organization', null, undefined, {
      serializer: jsonSerializer
    }),
    accessToken: useStorage<string | null>('account.accessToken', null)
  }),
  getters: {
    isLoggedIn: () => auth0.isAuthenticated.value
  },
  actions: {
    async verify(): Promise<boolean> {
      try {
        const resp = await axiosClient.get('user/verify')
        return resp.status === 200
      } catch (error) {
        this.logout()
        console.error(error)
        return false
      }
    },
    async logout(): Promise<void> {
      this.organization = null
      this.accessToken = null
      if (auth0.isAuthenticated) {
        auth0.logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        })
      }
    }
  }
})
