<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none" viewBox="0 0 29 29">
    <path
      fill="currentColor"
      d="m18.588 6.465.808.809L17.78 8.89l-.808-.808 1.616-1.617ZM21.82 9.698l-.808-.808-1.617 1.616.809.808 1.616-1.616Z"
    />
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="m12.93 7.273 5.657-5.657 8.081 8.081-5.657 5.657 1.617 1.617-8.89 8.889a3.428 3.428 0 0 1-4.849 0l-6.464-6.465a3.428 3.428 0 0 1 0-4.849l8.889-8.89 1.616 1.617Zm5.657-2.424 4.849 4.848-4.04 4.041-4.85-4.849 4.04-4.04Zm.808 12.122-8.081-8.082-7.273 7.273a1.143 1.143 0 0 0 0 1.617l6.465 6.465c.446.446 1.17.446 1.616 0l7.273-7.273Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
