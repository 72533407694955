<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useProjectStore } from '../stores/project'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import ErrorMessage from '../components/ErrorMessage.vue'
import type { Project } from '../types/project'
import type { User } from '../types/account'
import type { Machine } from '../types/machine'
import axiosClient from '../axiosClient'
import { useRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'

import PopoutPanel from '../components/PopoutPanel.vue'
import AlertDialogue from '../components/AlertDialogue.vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'

const { user } = useAuth0()

const router = useRouter()
const route = useRoute()
const projectStore = useProjectStore()
const isLoading = ref<boolean>(true)

const project = ref<Project>()
const errorMessage = ref<string>('')

const editProject = ref(false)
const newDescription = ref('')
const newName = ref('')
const resetNewInputs = () => {
  if (project.value) {
    newName.value = project.value.name
    newDescription.value = project.value.description
  }
}

const fetchProject = async () => {
  if (!route.params.id) return null
  const response = await projectStore.getProject(route.params.id as any)
  project.value = response
  resetNewInputs()
  isLoading.value = false
}
fetchProject()

const submitEdit = () => {
  axiosClient
    .put('project/' + project.value?.id, {
      name: newName.value,
      description: newDescription.value
    })
    .then(() => {
      editProject.value = false
      fetchProject()
    })
}

const closeProjectDialogue = ref(false)

const closeProject = () => {
  const endpoint = 'project/' + project.value?.id
  axiosClient.delete(endpoint).then((res) => {
    if (res.status === 200) {
      router.push({ path: '/projects' })
    }
  })
}

const addComputeDialogue = ref<boolean>(false)
const otherMachines = ref<Machine[]>([])
const selectedMachineIds = ref<string[]>([])

const fetchOtherMachines = () => {
  axiosClient.get('machine/list').then((res) => {
    if (res.data) {
      otherMachines.value = (res.data as Machine[]).filter((ma) => ma.project_id == 0)
    }
  })
}
fetchOtherMachines()

const openComputeDialogue = () => {
  addComputeDialogue.value = true
  fetchOtherMachines()
}

const addMachines = () => {
  axiosClient
    .post('project/' + project.value?.id + '/add_machines', selectedMachineIds.value)
    .then(() => {
      addComputeDialogue.value = false
      fetchProject()
      selectedMachineIds.value = []
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const removeMachines = (systemId: string) => {
  axiosClient
    .delete('project/' + project.value?.id + '/remove_machine/' + systemId)
    .then(() => {
      fetchProject()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const addMembersDialogue = ref<boolean>(false)
const otherUsers = ref<User[]>([])
const selectedUserIndices = ref<Number[]>([])

const openMembersDialogue = () => {
  axiosClient.get('user/list').then((response) => {
    const { data } = response
    otherUsers.value = data.filter((usr: any) => {
      for (let i = 0; i < (project.value?.members || []).length; ++i) {
        if (usr.user_id == project.value?.members[i].user_id) {
          return false
        }
      }
      return true
    })
  })
  addMembersDialogue.value = true
}

const addMembers = () => {
  if (selectedUserIndices.value.length === 0) return
  let ids: any[] = []
  selectedUserIndices.value.forEach((idx: any) => ids.push(otherUsers.value[idx].user_id))
  axiosClient.post('project/' + project.value?.id + '/add_members', ids).then(() => {
    addMembersDialogue.value = false
    fetchProject()
    selectedUserIndices.value = []
  })
}

const removeMember = (id: string) => {
  axiosClient.delete('project/' + project.value?.id + '/remove_member/' + id).then(() => {
    fetchProject()
  })
}

const stats = computed(() => [
  { name: 'Compute', value: project.value?.compute },
  { name: 'Spend', value: '$' + (project.value?.spend || 0.0).toFixed(2) },
  { name: 'Usage', value: '$' + (project.value?.rate || 0.0).toFixed(2) + '/h' }
])
</script>

<template>
  <div>
    <ErrorMessage :message="errorMessage" class="mb-4" />

    <div v-if="!isLoading && project && user">
      <div>
        <form v-if="editProject" @submit.prevent="submitEdit">
          <div
            class="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-gray-500 focus-within:ring-1 focus-within:ring-gray-500"
          >
            <label for="name" class="sr-only">Name</label>
            <input
              id="name"
              v-model="newName"
              type="text"
              name="name"
              class="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
              placeholder="Name"
            />
            <label for="description" class="sr-only">Description</label>
            <textarea
              id="description"
              v-model="newDescription"
              rows="5"
              name="description"
              class="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Write a description..."
            />

            <div
              class="flex items-center justify-right space-x-3 border-t border-airon-border px-2 py-2 sm:px-3"
            >
              <button
                type="button"
                class="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="
                  () => {
                    editProject = false
                    resetNewInputs()
                  }
                "
              >
                Cancel
              </button>
              <button
                type="submit"
                class="inline-flex items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <div v-else>
          <div class="flex justify-between">
            <h1
              class="text-2xl font-display font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
            >
              {{ project.name }}
            </h1>
            <div>
              <button
                type="button"
                class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                @click="editProject = true"
              >
                Edit
              </button>
            </div>
          </div>
          <p style="white-space: pre" class="mt-4 max-w-2xl text-sm text-gray-600">
            {{ project.description }}
          </p>
        </div>
      </div>

      <dl class="mt-8 mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-3 border-y">
        <div
          v-for="stat in stats"
          :key="stat.name"
          class="flex flex-wrap items-baseline justify-between bg-airon-lighter gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
        >
          <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
          <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            {{ stat.value }}
          </dd>
        </div>
      </dl>

      <div class="mt-8 divide-y divide-gray-200">
        <div class="py-5">
          <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Computes</h3>
            <button
              v-if="otherMachines.length > 0"
              type="button"
              class="relative inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              @click="openComputeDialogue()"
            >
              Add Compute
            </button>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-6">
          <div
            v-if="
              otherMachines.length === 0 &&
              (project.machines === undefined || project.machines.length === 0)
            "
            class="text-center border-2 border-dashed mx-auto max-w-md rounded-lg p-4"
          >
            <h3 class="text-sm font-semibold text-gray-900">No Instances</h3>
            <p class="mt-1 text-sm text-gray-500">
              Get started by launching a new compute instance.
            </p>
            <div class="mt-6">
              <RouterLink
                to="/"
                class="flex justify-center items-center text-sm font-medium text-airon-dark hover:text-black"
              >
                Launch an Instance
                <ArrowRightIcon class="h-4" />
              </RouterLink>
            </div>
          </div>
          <div
            v-if="
              otherMachines.length > 0 &&
              (project.machines === undefined || project.machines.length === 0)
            "
            class="text-center border-2 border-dashed mx-auto max-w-md rounded-lg p-4"
          >
            <h3 class="text-sm font-semibold text-gray-900">No Instances</h3>
            <p class="mt-1 text-sm text-gray-500">
              Get started by connecting a new compute instance.
            </p>
            <div class="mt-6 flex justify-center items-center">
              <button
                @click="openComputeDialogue()"
                class="block text-sm font-medium text-airon-dark hover:text-black"
              >
                Add Compute
              </button>
            </div>
          </div>
          <ul>
            <li
              v-for="machine in project.machines"
              :key="machine.system_id"
              class="flex justify-between"
            >
              <div class="flex gap-4 py-2">
                <div>
                  <p class="font-semibold leading-6 text-gray-900">{{ machine.name }}</p>
                </div>
                <div class="w-fit border border-zinc-300 text-xs py-1 px-2 rounded-md font-medium">
                  {{ machine.pool.name }}
                </div>
              </div>
              <div>
                <button
                  class="font-medium text-red-600 hover:text-red-900 text-sm"
                  @click="removeMachines(machine.system_id)"
                >
                  Remove
                  <span class="sr-only">, {{ machine.name }}</span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-8 divide-y divide-gray-200 overflow-hidden">
        <div class="py-5">
          <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Members</h3>
            <button
              type="button"
              class="relative inline-flex items-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              @click="openMembersDialogue()"
            >
              Add Members
            </button>
          </div>
        </div>
        <div class="px-4 py-5 sm:p-6">
          <ul role="list" class="divide-y divide-airon-border">
            <li
              v-for="member in project.members"
              :key="member.user_id"
              class="flex justify-between gap-x-6 py-5"
            >
              <div class="flex min-w-0 gap-x-4">
                <img
                  class="h-12 w-12 flex-none rounded-full bg-gray-50"
                  :src="member.picture_url"
                  alt=""
                />
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ member.name }}</p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ member.email }}</p>
                </div>
              </div>
              <div class="flex flex-none items-center gap-x-4">
                <button
                  v-if="user.sub != member.user_id"
                  class="font-medium text-red-600 hover:text-red-900 text-sm"
                  @click="removeMember(member.user_id)"
                >
                  Remove
                  <span class="sr-only">, {{ member.name }}</span>
                </button>
                <span v-else class="font-thin text-gray-400">You</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-8">
        <div class="border-b border-airon-border pb-2">
          <h3 class="font-bold">Danger Zone</h3>
        </div>
        <button
          type="button"
          class="mt-4 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
          @click="closeProjectDialogue = true"
        >
          Close Project
        </button>
      </div>

      <PopoutPanel
        title="Add Compute"
        description="Add compute instances to your project."
        :open="addComputeDialogue"
        @close="addComputeDialogue = false"
        @submit="addMachines()"
      >
        <ul class="space-y-4 pt-6">
          <li v-for="machine in otherMachines" :key="machine.system_id">
            <label
              class="hover:cursor-pointer block hover:bg-gray-50 has-[:checked]:ring-2 has-[:checked]:ring-gray-500 has-[:checked]:text-gray-900 has-[:checked]:bg-gray-50 p-4 rounded-lg"
            >
              <input
                v-model="selectedMachineIds"
                class="hidden"
                type="checkbox"
                :value="machine.system_id"
              />
              <p class="text-sm font-semibold leading-4 text-gray-900">{{ machine.name }}</p>
              <p class="mt-1 truncate text-xs text-gray-500">{{ machine.pool.name }}</p>
            </label>
          </li>
        </ul>
        <span v-if="otherMachines.length == 0" class="text-gray-600">
          No compute instances found.
          <RouterLink class="text-gray-600 hover:text-gray-500" to="/compute/start">
            Start one
          </RouterLink>
        </span>
      </PopoutPanel>

      <PopoutPanel
        title="Add Members"
        description="Give more users access to your project by adding them as members."
        :open="addMembersDialogue"
        @close="addMembersDialogue = false"
        @submit="addMembers"
      >
        <ul class="space-y-4 pt-6">
          <li v-for="(member, i) in otherUsers" :key="i">
            <label
              class="hover:cursor-pointer block hover:bg-gray-50 has-[:checked]:ring-2 has-[:checked]:ring-gray-500 has-[:checked]:text-gray-900 has-[:checked]:bg-gray-50 p-4 rounded-lg"
            >
              <input v-model="selectedUserIndices" class="hidden" type="checkbox" :value="i" />
              <div class="flex min-w-0 gap-x-4">
                <img
                  class="size-10 flex-none rounded-full bg-gray-50"
                  :src="member.picture_url"
                  alt=""
                />
                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-4 text-gray-900">{{ member.name }}</p>
                  <p class="mt-1 truncate text-xs text-gray-500">{{ member.email }}</p>
                </div>
              </div>
            </label>
          </li>
        </ul>
        <span v-if="otherUsers.length == 0" class="text-gray-600">No other users...</span>
      </PopoutPanel>
    </div>
    <SpinnerLoader v-else />

    <AlertDialogue
      title="Close Project"
      description="Are you sure you want to close this project?"
      :open="closeProjectDialogue"
      submit-text="Close"
      @close="closeProjectDialogue = false"
      @submit="closeProject()"
    />
  </div>
</template>
