<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <rect width="22" height="22" x="1" y="1" stroke="currentColor" stroke-width="2" rx="3" />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17 14.5s-1.5 2-4.5 2-4.5-2-4.5-2"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9 10a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1ZM16 10a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z"
    />
  </svg>
</template>
