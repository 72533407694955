import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import axiosClient from '../axiosClient'
import type { AironNotification } from '../types/account.d.ts'

export const useGlobalStore = defineStore({
  id: 'global',
  state: () => ({
    minimizedMenu: useStorage<Boolean>('global.minimizedMenu', false),
    notifications: useStorage<AironNotification[]>('global.notifications', [])
  }),
  getters: {
    isMinimizedMenu: (state) => state.minimizedMenu
  },
  actions: {
    toggleMinimizedMenu(): void {
      this.minimizedMenu = !this.minimizedMenu
    },
    fetchNotifications(): void {
      axiosClient.get('/user/notifications').then((res) => {
        this.notifications = res.data
      })
    }
  }
})
