<script lang="ts" setup></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m18 4 3-3M1 21l3-3M5.3 19.3a2.402 2.402 0 0 0 3.4 0L11 17l-6-6-2.3 2.3a2.4 2.4 0 0 0 0 3.4l2.6 2.6ZM6.498 12.5l2.5-2.5M9.501 15.5l2.5-2.5M11 5l6 6 2.3-2.3a2.399 2.399 0 0 0 0-3.4l-2.6-2.6a2.4 2.4 0 0 0-3.4 0L11 5Z"
    />
  </svg>
</template>
