import type { Project } from '../types/project'

import { defineStore } from 'pinia'
import axiosClient from '../axiosClient'

export const useProjectStore = defineStore({
  id: 'project',
  actions: {
    async getProjects(): Promise<Project[]> {
      const data = (await axiosClient.get('project/list')).data
      return data
    },
    async getProject(id: Number): Promise<Project> {
      const data = (await axiosClient.get('project/' + id)).data
      return data
    },
    async addProject(name: string, description: string, memberIDs: string[]) {
      const response = await axiosClient.post('project/add', {
        name: name,
        description: description,
        members: memberIDs
      })
      return response
    }
  }
})
